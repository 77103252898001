import { useState, useEffect, useRef } from "react";
import $, { event } from "jquery";
import './Style.css';
import { HiArrowDown } from 'react-icons/hi';
import Web3 from 'web3'
import { erc20Abi } from './Abi/erc20abi'
import bscimage from './images/bnb.png'
import maticimg from './images/polygon.svg'
import ethimg from './images/Eth.png'
import cupimage from './images/cup.png';
import usdtimage from './images/usdt.png';
import Navigate from "./components/navigate";
import config from './config/config';
import { transferbsctoken } from './transferbridge/transferbsc';
import { ToastContainer, toast } from "react-toastify";
import { IoCopyOutline } from "react-icons/io5";
import 'react-toastify/dist/ReactToastify.css';


function App() {

  const [accountaddress, setaccountaddress] = useState();
  const [tokenbalance, settokenbalance] = useState('0');
  const [depositamount, setdepositamount] = useState('');
  const [show, setShow] = useState(true);
  const [bscbal, setbscbal] = useState('0');
  const [dcxamountvalue, setdcxamountvalue] = useState(0);
  const userAddress = '0xD90cf321c82C310Ac09fDF6cD03b4E57C84868D7';
  const address1 ="165XVg21hiHGf7oqfaQ1icMKqTJhXSCDmd"
  const emailAddress = 'Info@cuptoken.trade';


  const bscnetwork = async () => {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    var web3js = new Web3(window.ethereum);
    try {
      if (accountaddress == undefined) {
        setbscbal(0)
        settokenbalance(0)
      }
      else if (chainId != config.BSC_CHAIN_ID) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3js.utils.toHex(config.BSC_CHAIN_ID) }]
        });

        const dweb3contract = new web3js.eth.Contract(erc20Abi, config.BSC_USDT_TOKEN_ADDRESS);
        const dweb3balance = await dweb3contract.methods.balanceOf(accountaddress).call();
        const balancesweb3dchain = web3js.utils.fromWei(dweb3balance, 'ether');
        const balancesdweb3chainfixed = Number(balancesweb3dchain).toFixed(8)
        settokenbalance(balancesdweb3chainfixed)
        getcuptokenbal(accountaddress)

      }
      else {
        const dweb3contract = new web3js.eth.Contract(erc20Abi, config.BSC_USDT_TOKEN_ADDRESS);
        const dweb3balance = await dweb3contract.methods.balanceOf(accountaddress).call();
        const balancesweb3dchain = web3js.utils.fromWei(dweb3balance, 'ether');
        const balancesdweb3chainfixed = Number(balancesweb3dchain).toFixed(8)
        settokenbalance(balancesdweb3chainfixed)

        getcuptokenbal(accountaddress)
      }
    } catch (err) {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: config.BSC_CHAIN_ID,
            chainName: "Binance Smart Chain",
            rpcUrls: [config.bsctestnetrpcurl],
            blockExplorerUrls: [config.bscblockExplorerUrls],
            nativeCurrency: {
              name: "Binance Smart Chain",
              symbol: "BNB",
              decimals: 18,
            },
          },
        ],
      });
    }
  }


  const getcuptokenbal = async (accountaddress) => {
    const web3dchainjs = new Web3(config.dchainrpcurl);
    const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.CUP_TOKEN_ADDRESS);
    const dchainbalance = await dchaincontract.methods.balanceOf(accountaddress).call();
    const balancesdchain = web3dchainjs.utils.fromWei(dchainbalance, 'ether');
    const balancesdchainfixed = Number(balancesdchain).toFixed(8)
    setbscbal(balancesdchainfixed)
  }

  const getfromvaluecontract = async (event) => {
    event.preventDefault()
    if (event.target.value == "") {
      setdcxamountvalue(0);
      setdepositamount(event.target.value);
    }
    else {
      setdepositamount(event.target.value);
      let amounts = event.target.value / 2024;
      setdcxamountvalue(amounts.toFixed(8));
      setShow(false)
    }
  }

  const gettovaluecontract = async (event) => {
    event.preventDefault()

    let amounts = event.target.value * 2024;
    setdepositamount(amounts)
    setdcxamountvalue(event.target.value);
    setShow(false)
  }

  const max = async () => {
    if (tokenbalance > 0) {
      const depositpercent = (tokenbalance).toString();
      let totokens = (tokenbalance / 2024).toFixed(8);
      let tokenstr = totokens.toString();
      setdepositamount(depositpercent)
      setdcxamountvalue(tokenstr)
    }
  }

  const transfer = async () => {
    if (dcxamountvalue === 0) {
      toast.error("Please enter value in amount...");
    } else if (accountaddress == undefined) {
      toast.error("CONNECT WALLET");
    }
    else {
      const web3dchainjs = new Web3(config.dchainrpcurl);
      const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.CUP_TOKEN_ADDRESS);
      const dchainbalance = await dchaincontract.methods.balanceOf(config.CUP_TOKEN_BRIDGE_ADDRESS).call();
      const balancesdchain = web3dchainjs.utils.fromWei(dchainbalance, 'ether');
      const balancesdchainfixed = Number(balancesdchain)
      if (dcxamountvalue < balancesdchainfixed) {
        let accountaddr = accountaddress;
        const chainid = await window.ethereum.request({ method: "eth_chainId" });
        if (chainid == config.BSC_CHAIN_ID) {
          const setdatas = {
            useraccount: accountaddr,
            deposits: depositamount,
            toamountchange: dcxamountvalue
          };
          await transferbsctoken(setdatas);
        }
        else {
          toast.error("wrong network");
        }
      }
      else {
        toast.error("Insufficient amount");
      }
    }
  }

  async function bridges() {
    bscnetwork();
    $('.switch-tabs-section-bsc').css('background', '#EAC368');
    $('.switch-tabs-section-bsc').css('color', '#FFF');
    $('.switch-tabs-section-poly').css('background', 'transparent');
    $('.switch-tabs-section-poly').css('color', '#FFF');
    $('.switch-tabs-section-eth').css('background', 'transparent');
    $('.switch-tabs-section-eth').css('color', '#FFF');
    $('.switch-tabs-section-dcx').css('background', 'transparent');
    $('.switch-tabs-section-dcx').css('color', '#FFF');
  }


  //networkcheck
  const networkcheck = async (e) => {
    bscnetwork();
    $('.switch-tabs-section-bsc').css('background', '#EAC368');
    $('.switch-tabs-section-bsc').css('color', '#FFF');
    $('.switch-tabs-section-poly').css('background', 'transparent');
    $('.switch-tabs-section-poly').css('color', '#FFF');
    $('.switch-tabs-section-eth').css('background', 'transparent');
    $('.switch-tabs-section-eth').css('color', '#FFF');
    $('.switch-tabs-section-dcx').css('background', 'transparent');
    $('.switch-tabs-section-dcx').css('color', '#FFF');
  }



  useEffect(() => {

    if (window.ethereum) {
      const chainaccounts = sessionStorage.getItem('chainaccounts')
      if (chainaccounts == null) {
        $('.switch-tabs-section-poly').css('background', 'transparent');
        $('.switch-tabs-section-poly').css('color', '#FFF');
        $('.switch-tabs-section-bsc').css('background', 'transparent');
        $('.switch-tabs-section-bsc').css('color', '#FFF');
        $('.switch-tabs-section-eth').css('background', 'transparent');
        $('.switch-tabs-section-eth').css('color', '#FFF');
        $('.switch-tabs-section-dcx').css('background', '#EAC368');
        $('.switch-tabs-section-dcx').css('color', '#FFF');
      }
      else {
        setaccountaddress(chainaccounts)
        bridges()
      }
    }
    else {
      toast.warn("Check Metamask is Installed", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  });

  const handleKeyPress = async (event) => {
    console.log("")
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }
  const copyToClipboard = () => {
    navigator.clipboard.writeText(userAddress)
      .then(
        toast.success("Copied..")
      )

  }
  const copyToClipboard2 = () => {
    navigator.clipboard.writeText(address1)
      .then(
        toast.success("Copied..")
      )
  }

  const copyToClipboardEmail = () => {
    navigator.clipboard.writeText(emailAddress)
      .then(toast.success("Copied.."))
  }

  return (
    <div className="App">
      <Navigate />
      <section className="py-lg-5 py-4 new-switch-tabs-btn-group">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 switch-tabs-section">
              <div className="btn-group switch-tabs-btn-group switch-tabs-btn-group-2
              ">
                <button type="button" onClick={() => networkcheck("BSC")} className="btn switch-tabs-section-bsc">
                  <img className="network-image" src={bscimage} alt="BSC Network" />Purchase by using <br /> BSC Network
                </button>
              </div>
            </div>
            <div className="col-lg-8 switch-banner-section pb-lg-5 mt-4">
              <p className="switch-banner-section-text-1 text-center">
                Buy 1 CUP at 2024 USDT
              </p>

              <div className="switch-banner-section-inner">
                <div className="switch-banner-amount-section">
                  <div className="row">
                    <div className="col-lg-6 col-4 text-start">
                      <span className="switch-banner-section-text-2">
                        Amount You spend
                      </span>
                    </div>
                    <div className="col text-end">
                      <span className="switch-banner-section-text-2">
                        Balance : <span className="switch-banner-section-text-3">
                          {tokenbalance}
                        </span>
                      </span>
                      <br className="mobile-break" />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-lg-6 col-6 text-start">
                      <input type="number" className="form-control form-control-amount" min="" placeholder="Enter the amount" id="from_amount" onChange={(e) => getfromvaluecontract(e)} value={depositamount} onKeyPress={handleKeyPress} />
                    </div>
                    <div className="col amount-select-section">
                      <div className="row">
                        <div className="col-lg-4 col-6 text-lg-end">
                          <span>
                            <button type="button" className="btn-max" onClick={max}>
                              MAX
                            </button>
                          </span>
                        </div>
                        <div className="col text-end text-lg-center">
                          <span className="amount-select-from">
                            <img src={usdtimage} className="amount-select-image" alt="networklogo" /><span className="amount-select-name">USDT</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="switch-banner-centericon-section">
                  <div className="row py-2">
                    <div className="col">
                      <HiArrowDown className="centericon-swap" />
                    </div>
                  </div>
                </div>

                <div className="switch-banner-amount-section">
                  <div className="row">
                    <div className="col-lg-6 col-4 text-start">
                      <span className="switch-banner-section-text-2">
                        Amount You buy
                      </span>
                    </div>
                    <div className="col text-end">
                      <span className="switch-banner-section-text-2">
                        Balance : <span className="switch-banner-section-text-3">
                          {bscbal}
                        </span>
                      </span>
                      <br className="mobile-break" />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-lg-6 col-12 text-start">
                      <span className="form-control form-control-amount2 " placeholder="0.00" id="to_amount">
                        <input type="number" min="0" className="form-control form-control-amount" placeholder="Enter the amount" id="to_amount" onChange={(e) => gettovaluecontract(e)}
                          value={dcxamountvalue} onKeyPress={handleKeyPress} />
                      </span>
                    </div>
                    <div className="col amount-select-section mt-2 mt-lg-0">
                      <div className="row flex-lg-row-reVRS">
                        <div className="col text-end text-lg-end">
                          <span className="amount-select-to">
                            <img src={cupimage} className="amount-select-image" alt="networklogo" /><span className="amount-select-name">CUP</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="button-container-1 my-lg-4 my-4">
                  <span className="mas">Buy</span>
                  <button id="work" type="button" name="Hover" onClick={() => transfer()}>
                    BUY
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4">
        <div className="container  pb-5">
          <div className="row">
            <div className="col-lg-12">
              <h2>Tutorials Videos</h2>
              <div className="row align-items-center justify-content-center mt-3">
                <div className="col-lg-3">
                  <div className="custom-presale-box">
                    <p>How to Download D-Wallet Extension</p>
                    <a href="https://www.youtube.com/watch?v=s1FIEC_q9oUMetamask:" target="_blank"><button className="custom-pre-sale-btn-1">Learn More</button></a>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="custom-presale-box">
                    <p>How to Add D-Chain
                      on Metamask Wallet</p>
                    <a href="https://www.youtube.com/watch?v=foepXi-harsSalespad:" target="_blank"><button className="custom-pre-sale-btn-1">Learn More</button></a>

                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="custom-presale-box">
                    <p>How to Buy with <br></br>Salespad</p>
                   <button className="custom-pre-sale-btn-1">Learn More</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4" >
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-12">
              <h2>Other Purchase Methods</h2>
              <div className="row align-items-center justify-content-center mt-3">
                <div className="col-lg-9">
                  <div className="custom-other-1 text-start d-flex justify-content-center">
                    <button className="custom-pre-sale-btn-2">Buy with Crypto Transfer</button>
                    <p className="ms-3 mb-0">
                      Transfer your cryptocurrency to Wallet Address:<br />
                      <div className="d-flex flex-row">
                        <label className="dict-user-add-copp">{userAddress}</label>
                        <button className="click-copy mx-1" onClick={copyToClipboard}>
                          <IoCopyOutline />
                        </button>
                      </div>

                      then send the transaction screenshot and hash to Email:
                      <label className="dict-user-add-copp">{emailAddress}</label>
                      <button className="click-copy mx-1" onClick={copyToClipboardEmail}>
                        <IoCopyOutline />
                      </button>
                      & receive CUP tokens to the sender's address.
                      If you wish for the CUP tokens to be sent to a different address, please include that address in your email as well.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="custom-other-2">
                    <p className=""><span className="fw-bold">Crypto Accepted</span> <br></br>USDT on Network: <span><img src={bscimage} className='custom-coin-img' /></span> <span><img src={ethimg} className='custom-coin-img' /></span> <span><img src={maticimg} className='custom-coin-img' /></span></p>
                  </div>
                </div>


              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="row align-items-end justify-content-end ">
                    <div className="col-lg-3">
                      <div className="custom-other-2 custom-other-3">
                        <p className=""><span className="fw-bold">Bitcoin BTC</span> </p>
                        <p className="dict-user-add-cop-1 mb-0">165XVg21hiHGf7... <IoCopyOutline className="ms-1" onClick={copyToClipboard2}/></p>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-3" >
                <div className="col-lg-12">
                  <div className="fs-4">OR</div>
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-9">
                  <div className="custom-other-1 text-start d-flex justify-content-center">
                    <button className="custom-pre-sale-btn-2">Buy with <br></br>Bank Transfer</button>
                    <p className="ms-3 mb-0">Email us at Info@cuptoken.trade with the amount of Cup Tokens you wish to Purchase and Your Wallet Address. We will Provide Information Via Mail to process the Bank transaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4">
        <div className="container  pb-5">
          <div className="row">
            <div className="col">
              <h5 className="mb-4">TOKEN PRESALE INFORMATION GUIDE</h5>
              <div className="row text-start px-lg-5 px-2">
                <div className="col-lg-5">
                  <h5>What is CUP TOKEN ?</h5>
                </div>
                <div className="col-lg-7">
                  <h6>CUP TOKEN is a Real-World asset and RWA Project</h6>
                  <h6>CUP TOKEN is backed by UFCP Ultra Fine Copper Powder</h6>
                  <p className="mb-4">Ultrafine copper powder is one of the most expensive elements
                    in the world.This <br />is due to the amount of material
                    used and the complex production process.</p>
                  <p >For more Information <br /><br /> Click here : <a style={{ color: "#D2B14A", textDecoration: "underline" }} href="https://cuptoken.trade/isotope" target="_blank">What is our Asset?</a></p>
                </div>
              </div>
              <div className="row text-start px-lg-5 px-2 mt-4">
                <div className="col-lg-5">
                  <h5>What is the Product  CUP TOKEN<br /> representing and the price? </h5>
                </div>
                <div className="col-lg-7">
                  <h6>The product consists of 2 stable isotopes </h6>
                  <p className="mb-0">CU 63 (61,1 %) and CU 65 (30,9 %) </p>
                  <p className="mb-0">purity 99,999 %</p>
                  <p className="mb-0">CU 63 price 2289 USD by gram </p>
                  <p className="mb-0">CU 65 price 5559 USD by gram</p>
                  <p className="mb-0 d-flex align-items-center"><h6 className="mb-0 me-1">Product Price 3550 USD</h6>by gram</p>
                  <small className="position-relative" style={{ fontSize: "11px", top: "-6px" }}>(Average from the individual prices above)</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-1 mb-4">
        <div className="container new-switch-tabs-section-2 pb-5">
          <div className="row">
            <div className="col">
              <h5 className="new-switch-tabs-section-2-inner-1 mb-2">Presale Advantage: Unlock Exclusive Benefits</h5>
              <p className="fs-18 mb-3 w-75 mx-auto">As an early participant in the CUP TOKEN Presale, you gain exclusive access to a myriad of benefits that set the stage for a rewarding investment experience.</p>
              <h5>Check out the Advantages:</h5>
              <div className="new-switch-tabs-section-3">
                <table class="table mt-4">
                  <thead>
                    <tr>
                      <th scope="col">
                        <div>CUP TOKEN</div>
                        <div>Presale Sold Per:</div>
                      </th>
                      <th scope="col">Price</th>
                      <th scope="col">Discount</th>
                      <th scope="col">Staking APY</th>
                      <th scope="col">
                        <div>Total price advantage</div>
                        <div>(including staking)</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1. Per Gram</td>
                      <td>2,024 USD/ Gram</td>
                      <td>E.g 43%</td>
                      <td>10%</td>
                      <td>53%</td>
                    </tr>
                    <tr>
                      <td>2. Whole ampule</td>
                      <td>1,619 USD/ Gram</td>
                      <td>E.g 63%</td>
                      <td>10%</td>
                      <td>73%</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}


export default App;