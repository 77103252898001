import Web3 from "web3";
import { erc20Abi } from "../Abi/erc20abi";
import { homebridgeAbi } from "../Abi/homebridgeabi";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../config/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../Style.css';




export const transferbsctoken = async (data = {}) => {
  
  var web3js = new Web3(window.ethereum);
  const accounts = data.useraccount;
  const depositamount = data.deposits;
  let toamountchange = data.toamountchange;
  let networkname = "BSC";

  const bscbridgecontract = new web3js.eth.Contract(homebridgeAbi,config.BSC_BRIDGE_ADDRESS);
  const btomfromcontract = new web3js.eth.Contract(erc20Abi,config.BSC_USDT_TOKEN_ADDRESS);
  const transfertoken = web3js.utils.toWei(depositamount, "ether");

    if(transfertoken > 0) {
      await btomfromcontract.methods.approve(
        config.BSC_BRIDGE_ADDRESS,
         transfertoken).send({ from: accounts });
      Swal.fire({
        icon: "warning",
        background: "#F4E5BE",
        border: "1px solid #00e5ff21",
        color: "#000000",
        title: "Transaction is Processing",
        html: "Do Not Refresh or Reload the Page",
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      const transferfn = await bscbridgecontract.methods.ExchangeETHforToken(transfertoken)
        .send({
          from: accounts,
        }).on('error', async function(error, receipt) { 
          if(error.code == 4001) {
            window.location.reload()
          }
      })

      var transferobjects = {
        accounts,
        networkname,
        toamountchange,
        depositamount
      };
      const dcoinget = await axios.post(config.BACKEND_URL, transferobjects);
        Swal.fire({
          icon: "success",
          background: "#F4E5BE",
          border: "1px solid #00e5ff21",
          color: "#000000",
          title: "Transaction confirmed",
          html:
            "Your transaction hash is:<br/><br/>" +
            `<a href=${
              "https://mainnet-explorer.d-ecosystem.io/tx/" + dcoinget.data
            }  target="_blank" style="color:#EAC368;">${dcoinget.data}</a>
              <br/><br/>`,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(); 
          }
        });
        
      
    } else {
      toast.error("Insufficient amount");
    }
};