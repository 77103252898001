import React from "react";
import { VscArrowSwap } from 'react-icons/vsc';
import { MdLeaderboard } from 'react-icons/md';
import WalletConnector from '../connector/wallet-connector';
import DIClogo from "../images/dictys-logo.webp"


const Navigate = () => {

return (
    <>
    <nav className="navbar navbar-expand-lg navbar-light navbar-section">
    <div className="container">
      <a className="navbar-brand" href="https://cuptoken.trade/" ><img src={DIClogo} alt="logo" /></a>
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 mobile-transaction">
       
        <li className="nav-item">
          <a href="transactions#bsc" className="nav-link connect-trans-button me-3 nav-link-ltr"><VscArrowSwap className='me-2 nav-icons' /><span className='tabs-none'> TRANSACTIONS</span></a>
        </li>
      </ul>
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a href="transactions#bsc" className="nav-link connect-trans-button desktop-transaction me-3 nav-link-ltr"><VscArrowSwap className='me-2 nav-icons' /><span className='tabs-none'> TRANSACTIONS</span></a>
        </li>
          <WalletConnector/>

      </ul>
    </div>
  </nav>
  </>
)
};

export default Navigate;