import { useState, useEffect } from "react";
import $ from "jquery";
import './Style.css';
import bscimage from './images/bscimages.svg.png'
import axios from 'axios';
import InputDataDecoder from 'ethereum-input-data-decoder'
import { homebridgeAbi } from './Abi/homebridgeabi'

import { BiArrowBack, BiLeftArrowAlt } from 'react-icons/bi';
import DataTable, { createTheme } from 'react-data-table-component';

import { BsWallet2 } from "react-icons/bs";
import { VscArrowSwap } from 'react-icons/vsc';
import DIClogo from "./images/dictys-logo.webp"
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";


createTheme('solarized', {
    text: {
        primary: '#000',
        secondary: '#000',
    },
    background: {
        default: '#eac3686e',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#FFFFFF34',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');


function Transaction() {

    const [connectedAccount,setconnectedAccount]  = useState();
    const [gettxdetails,setgettxdetails] = useState([]);

    const columns = [
        {
            name: "Date",
            selector: (row) => row.sno,
            sortable: true,
        },
        {
            name: "Tnx Hash",
            selector: (row) => <a href={row.link} target={"_blank"}>{row.hash}</a>,
            sortable: true,
        },
        {
            name: "From",
            selector: (row) => row.from,
            sortable: true,
            width: "200px"
        },
        {
            name: "To",
            selector: (row) => row.to,
            sortable: true,
            width: "200px"
        },
        {
            name: "Amount",
            selector: (row) => row.value,
            sortable: true,
        },
    ];

    const handleSort = (column, sortDirection) => console.log("=====", column.selector, sortDirection);

    useEffect(() => {
        if(window.ethereum) {
            const reloadCount = sessionStorage.getItem('chainaccounts');
            if(reloadCount != null) {
                let accountslice = reloadCount.slice(0, 7) + ".." + reloadCount.slice(-5);
                setconnectedAccount(accountslice)
                bsctxdetails()
            }
            else {
                $("#hidedisconnect").hide(); 
                $("#hideconnect").show(); 
                setconnectedAccount("CONNECT WALLET")
                toast.error("Connect Wallet");
            }
        }
    },[])


    const connectWeb3Wallet = async () => {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        sessionStorage.setItem('chainaccounts', accounts[0])
        let accountslice = accounts[0].slice(0, 7) + ".." + accounts[0].slice(-5);
        setconnectedAccount(accountslice)
        $("#hidedisconnect").show(); 
        bsctxdetails()
    }

    const disconnectWeb3Modal = async () => {
        setconnectedAccount("CONNECT WALLET")
        sessionStorage.removeItem('chainaccounts')
        window.location.reload();
    }


    const bsctxdetails = async () => {
        const chainaccounts = sessionStorage.getItem('chainaccounts')
        if (chainaccounts !== null) {
            $(".table-bsc").show();
            $('.switch-tabs-section-dcx').css('background', 'transparent');
            $('.switch-tabs-section-dcx').css('color', '#FFF');
            $('.switch-tabs-section-eth').css('background', 'transparent');
            $('.switch-tabs-section-eth').css('color', '#FFF');
            $('.switch-tabs-section-poly').css('background', 'transparent');
            $('.switch-tabs-section-poly').css('color', '#FFF');
            $('.switch-tabs-section-bsc').css('background', '#eac3686e');
            $('.switch-tabs-section-bsc').css('color', '#FFF');

            const decoder = new InputDataDecoder(homebridgeAbi);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const gettxdetails = ['']
            const responres = await axios.get("https://api.bscscan.com/api?module=account&action=txlist&contractaddress=0x99f7d9247c2d8e78f3d67be54d460d9a798a02c6&address=" + account[0] + "&page=1&offset=10&sort=desc&apikey=NCN23G4SGIEYWR3XWGR3XXWKMXT61BPE4I");
                console.log("responres=",responres.data);
            for (var i = 0; i < responres.data.result.length; i++) {
                if (responres.data.result[i].to == "0x99f7d9247c2d8e78f3d67be54d460d9a798a02c6") {

                    let unixTimestamp = responres.data.result[i].timeStamp;
                    const utcDateTime = moment.unix(unixTimestamp).utc().format('YYYY/MM/DD HH:mm:ss');

                    const resultinput = await decoder.decodeData(responres.data.result[i].input);

                    const hexToDecimal2 = (parseInt(resultinput.inputs[0]._hex, 16)) / 1e18;
                    var car = {
                        "sno": utcDateTime + " UTC",
                        "link": "https://bscscan.com/tx/" + responres.data.result[i].hash,
                        "hash": responres.data.result[i].hash,
                        "from": responres.data.result[i].from,
                        "to": responres.data.result[i].to,
                        "value": hexToDecimal2 + " USDT"
                    }
                    gettxdetails.push(car);
                    const setpaths = gettxdetails.filter(Boolean);
                    setgettxdetails(setpaths)
                }
            }
            if (gettxdetails.length <= 1) {
                $(".table-bsc").hide();
            }
        } else {
            $('.switch-tabs-section-dcx').css('background', 'transparent');
            $('.switch-tabs-section-dcx').css('color', '#FFF');
            $('.switch-tabs-section-eth').css('background', 'transparent');
            $('.switch-tabs-section-eth').css('color', '#FFF');
            $('.switch-tabs-section-poly').css('background', 'transparent');
            $('.switch-tabs-section-poly').css('color', '#FFF');
            $('.switch-tabs-section-bsc').css('background', '#eac3686e');
            $('.switch-tabs-section-bsc').css('color', '#FFF');
        }
    }


    return (
        <div className="App">
            <nav className="navbar navbar-expand-lg navbar-light navbar-section">
                <div className="container">
                    <a className="navbar-brand" href="https://cuptoken.trade/"><img src={DIClogo} alt="logo" /></a>
                   
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <button className="nav-link connect-wallet-button  my-2" id="hideconnect" onClick={connectWeb3Wallet}>
                            <BsWallet2 className='me-2' />
                            {connectedAccount == "" || connectedAccount == null ? "CONNECT WALLET" : connectedAccount}
                        </button>
                        <button className="nav-link connect-wallet-button-2 my-2 ms-2" id="hidedisconnect" onClick={disconnectWeb3Modal}>
                            <BsWallet2 className='me-2' />
                            {connectedAccount !== "" ? "Disconnect" : ""}
                        </button>
                    </ul>
                </div>
            </nav>
            <section className='py-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-12 switch-tabs-section'>
                            <div className="text-start mb-lg-0 mb-3">
                                <a href="/"><span className="trans-back-button"><BiLeftArrowAlt /></span></a>
                            </div>
                            <div className="btn-group switch-tabs-btn-group">
                                <a href="#bsc"><button type="button" onClick={bsctxdetails} className="btn switch-tabs-section-bsc"><img className="network-image" src={bscimage} alt="BSC Network" />BSC Network</button></a>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-5">
                            <div className="table-bsc gSVZoG">
                                <DataTable
                                    columns={columns}
                                    data={gettxdetails}
                                    defaultSortFieldId
                                    pagination={5}
                                    highlightOnHover
                                    theme="solarized"
                                    onSort={handleSort}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


export default Transaction;
