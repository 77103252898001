let config = {};
let environment = "live";

if (environment === "local") {
  config = {
    FRONTEND_URL : "http://localhost:3000/",
    BACKEND_URL : "http://localhost:4000/dusdtokentransfer",

    BSC_BRIDGE_ADDRESS:"0x064A9Bd694DAf2A3adc8D87d48478f31D4BF6539",
    CUP_TOKEN_BRIDGE_ADDRESS: "0xB1F366b980Ed61581F07EA8e79c7c1ad2B1680b5",

    bsctestnetrpcurl:"https://data-seed-prebsc-1-s2.binance.org:8545",

    bscblockExplorerUrls:"https://bsc-testnet.publicnode.com/",
    dchainrpcurl : "https://mainnetcoin.d-ecosystem.io/",

    BSC_USDT_TOKEN_ADDRESS:"0xe6Fc9E04AE701cf5BA109Ff7168A1510f5D3f768",
    CUP_TOKEN_ADDRESS :"0x1E773a1a300F255d03FA48D60e8B3dE6089FB984",

    BSC_CHAIN_ID :"0x61",
    DCX_CHAIN_ID : "0x14a33"
  };
} else {
  config = {
    FRONTEND_URL : "https://presale.cuptoken.trade/",
    BACKEND_URL : "https://api-presale.cuptoken.trade/dusdtokentransfer",

    BSC_BRIDGE_ADDRESS:"0x99f7d9247c2d8e78f3d67be54d460d9a798a02c6",
    CUP_TOKEN_BRIDGE_ADDRESS: "0xD7611e0469DC05FDe198160C3b9eb0e968753dDC",

    bsctestnetrpcurl:"https://bsc-dataseed1.binance.org/",

    bscblockExplorerUrls:"https://bscscan.com/",
    dchainrpcurl : "https://mainnetcoin.d-ecosystem.io/",

    BSC_USDT_TOKEN_ADDRESS:"0x55d398326f99059ff775485246999027b3197955",
    CUP_TOKEN_ADDRESS :"0xf1dDA922fB5aCe0736957f8Ea3cB126016fa6602",

    BSC_CHAIN_ID :"0x38",
    DCX_CHAIN_ID : "0x1498"
  };
}

export default config;